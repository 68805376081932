







































































































































import {  AlunoTurma, Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { TurmaService } from "@/core/services/geral";
import {Component} from "vue-property-decorator";


@Component
export default class RelatorioChamada extends PageBase{
    service = new TurmaService();
    item: any = {
        turma: "",
        hospedagem: "",
        valorHospedagem: 0,
        nome: "",
        percentualComissao: 0,
        comissaoAlunos: 0,
        comissaoEquipe: 0,
        comissaoTotal: 0,
        comissaoPagarEquipe: 0,
        alunos: [],
        equipe: []
    };
    lista: AlunoTurma[] = [];
    filtro = {
        turmaId: 0,
        gestorId: 0
    }
    overlay: boolean = false;

    mounted() {

        this.filtro.turmaId = Number(this.$route.query.turmaId);
        this.filtro.gestorId = Number(this.$route.query.gestorId)

        this.overlay = true;

        this.service.RelatorioComissao(this.filtro.turmaId, this.filtro.gestorId).then(
            res =>{
                this.item = res.data;
            },
            err => {
                if (!err.response){
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
                else{
                    this.$swal("Aviso",err.response.data,"error")
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }

    AbrirComissao(id: number){
        
        let routeLink = this.$router.resolve({name:"impressaoComissao",
            query:{
                turmaId: this.filtro.turmaId.toString(),
                gestorId: id.toString()
            }
        });

        window.open(routeLink.href,'_blank');            
    }
}
